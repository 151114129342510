import { withRouter } from 'react-router-dom';
import { parse } from 'qs';
import authService from '@/components/authorization/AuthorizeService';

const QueryAuthorization = (props: any) => {
    const accessToken = parse(props.location.search, { ignoreQueryPrefix: true }).access_token;
    if (typeof accessToken === 'string') {
        authService.ensureUserManagerInitialized().then(() => {
            authService.storeUser({
                access_token: accessToken,
                scope: '',
                id_token: '',
                token_type: '',
                expired: false,
                expires_in: 7200,
                state: '',
                profile: { iss: 'x', sub: '', aud: '', exp: 0, iat: 0 },
                scopes: [],
                expires_at: 0,
                refresh_token: '',
                session_state: '',
                toStorageString(): string {
                    return `{"id_token":"","access_token":"${accessToken}","refresh_token":"","token_type":"","":"","profile":{"sub":"","jti":"","auth_time":0,"trigram":"","uid":"","roles":[],"given_name":"","family_name":"","email":"","pi.sri":"","s_hash":""},"expires_at":0}`;
                },
            });
        });
    }

    return <div />;
};

export default withRouter(QueryAuthorization);
