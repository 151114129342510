export enum SkeletonType {
    default,
    Header = 'header',
    Footer = 'footer',
    FullPageSpinner = 'fullPageSpinner',
    BottomNavigationBarMobile = 'bottomNavigationBarMobile',
    HighlightSection = 'highlightSection',
    FullWidthCardSection = 'fullWidthCardSection',
    FeaturedCarousel = 'featuredCarousel',
    PhotoGallery = 'photoGallery',
}

type SkeletonProps = {
    variant: SkeletonType;
};

export default SkeletonProps;
