/* eslint-disable space-before-function-paren */
/* eslint-disable max-len */
import { User, UserManager, WebStorageStateStore } from 'oidc-client';
import { mediaHubDb } from 'src/utils/database';
import { ApplicationPaths, ApplicationName } from './ApiAuthorizationConstants';

export type AuthorizedUser = {
    profile: any;
};

export type SignInResult = {
    message?: string;
    status?: string;
    state?: string;
};

export type SignOutResult = {
    message?: string;
    status?: string;
    state?: string;
};

export const requestRefreshRouteCachingWithNewToken = async () => {
    const registration = await navigator['serviceWorker']?.getRegistration();

    registration?.active?.postMessage('refreshCacheWithNewToken');
};

export class AuthorizeService {
    _callbacks: Array<any> = [];
    _nextSubscriptionId = 0;
    _user: AuthorizedUser | null = null;
    _isAuthenticated = false;

    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    _popUpDisabled = true;
    private userManager: any;

    async isAuthenticated() {
        const user = await this.getUser();

        return !!user;
    }

    async removeUser() {
        await this.userManager.removeUser();
        await mediaHubDb.userData.delete('accessToken');
        await requestRefreshRouteCachingWithNewToken();
    }

    async getUser() {
        if (this._user && this._user.profile) {
            return this._user.profile;
        }

        await this.ensureUserManagerInitialized();
        const user = await this.userManager.getUser();
        if (user && user.expired) return null;

        return user && user.profile;
    }

    async isTokenExpired(): Promise<boolean> {
        if (this._user && this._user.profile) {
            return this._user.profile;
        }

        await this.ensureUserManagerInitialized();
        const user = await this.userManager.getUser();
        return user?.expired;
    }

    async storeUser(user: User) {
        await this.ensureUserManagerInitialized();

        await this.userManager.storeUser(user);
        await mediaHubDb.userData.add({ key: 'accessToken', token: user.access_token });
        await requestRefreshRouteCachingWithNewToken();

        return;
    }

    async getAccessToken() {
        await this.ensureUserManagerInitialized();
        const user = await this.userManager.getUser();
        return user && user.access_token;
    }

    async getIdToken() {
        await this.ensureUserManagerInitialized();
        const user = await this.userManager.getUser();
        return user && user.id_token;
    }

    async getImpersonationTrigram() {
        const store = new WebStorageStateStore({ prefix: 'Mediahub:' });
        const impersonationTrigram = await store.get('ImpersonationTrigram');

        return impersonationTrigram;
    }

    navigateToReturnUrl(returnUrl) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }

    async login(returnUrl) {
        const state = { returnUrl };
        const result = await authService.signIn(state);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                break;
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(returnUrl);
                break;
            case AuthenticationResultStatus.Fail:
                break;
            default:
                throw new Error(`Invalid status result ${result.status}.`);
        }
    }

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    async signIn(state): Promise<SignInResult> {
        await this.ensureUserManagerInitialized();
        try {
            const silentUser = await this.userManager.signinSilent(this.createArguments(null));
            await this.updateState(silentUser);
            return this.success(state);
        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication

            try {
                if (this._popUpDisabled) {
                    throw new Error(
                        'Popup disabled. Change AuthorizeService.js:AuthorizeService._popupDisabled to false to enable it.',
                    );
                }

                const popUpUser = await this.userManager.signinPopup(this.createArguments(null));
                await this.updateState(popUpUser);
                return this.success(state);
            } catch (popUpError) {
                if ((popUpError as Error).message === 'Popup window closed') {
                    // The user explicitly cancelled the login action by closing an opened popup.
                    return this.error('The user closed the window.');
                } else if (!this._popUpDisabled) {
                }

                // PopUps might be blocked by the user, fallback to redirect
                try {
                    await this.userManager.signinRedirect(this.createArguments(state));
                    return this.redirect();
                } catch (redirectError) {
                    return this.error(redirectError);
                }
            }
        }
    }

    async completeSignIn(url): Promise<SignInResult> {
        try {
            await this.ensureUserManagerInitialized();
            const user = await this.userManager.signinCallback(url);
            await this.updateState(user);
            return this.success(user && user.state);
        } catch (error) {
            return this.error('There was an error signing in.');
        }
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOut(state): Promise<SignOutResult> {
        await this.ensureUserManagerInitialized();
        try {
            if (this._popUpDisabled) {
                throw new Error(
                    'Popup disabled. Change AuthorizeService.js:AuthorizeService._popupDisabled to false to enable it.',
                );
            }

            await this.userManager.signoutPopup(this.createArguments(null));
            await this.updateState(undefined);
            return this.success(state);
        } catch (popupSignOutError) {
            try {
                await this.userManager.signoutRedirect(this.createArguments(state));
                return this.redirect();
            } catch (redirectSignOutError) {
                return this.error(redirectSignOutError);
            }
        }
    }

    async completeSignOut(url): Promise<SignOutResult> {
        await this.ensureUserManagerInitialized();
        try {
            const response = await this.userManager.signoutCallback(url);
            await this.updateState(null);
            return this.success(response && response.data);
        } catch (error) {
            return this.error(error);
        }
    }

    async updateState(user) {
        this._user = user;
        this._isAuthenticated = !!this._user;
        this.notifySubscribers();
        await mediaHubDb.userData.add({ key: 'accessToken', token: user?.access_token });
        await requestRefreshRouteCachingWithNewToken();
    }

    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) =>
                element.subscription === subscriptionId ? { found: true, index } : { found: false },
            )
            .filter((element) => element.found);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index as number, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    createArguments(state) {
        return { useReplaceToNavigate: true, data: state };
    }

    error(message) {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    success(state) {
        return { status: AuthenticationResultStatus.Success, state };
    }

    redirect() {
        return { status: AuthenticationResultStatus.Redirect };
    }

    async tryRefreshToken(): Promise<void> {
        try {
            await this.userManager.signinSilent();
        } catch (e) {}
    }

    async ensureUserManagerInitialized() {
        if (this.userManager !== undefined) {
            return;
        }

        // @ts-ignore
        const response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl);
        if (!response.ok) {
            throw new Error(`Could not load settings for '${ApplicationName}'`);
        }

        const settings = await response.json();
        settings.automaticSilentRenew = true;
        settings.includeIdTokenInSilentRenew = true;
        settings.loadUserInfo = false;
        settings.userStore = new WebStorageStateStore({
            prefix: ApplicationName,
        });

        this.userManager = new UserManager(settings);

        this.userManager.events.addUserSignedOut(async () => {
            await this.userManager.removeUser();
            await this.updateState(undefined);
        });

        this.userManager.events.addAccessTokenExpired(async () => {
            try {
                await this.userManager.signinSilent();
            } catch (e) {
                await this.userManager.removeUser();
                if (window.serviceNowOnLogout !== undefined) {
                    window.serviceNowOnLogout();
                }
            }
        });
    }

    static get instance() {
        return authService;
    }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail',
};
