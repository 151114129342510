import Loading from '../loading/loading';
import SkeletonProps, { SkeletonType } from './dto';
import BottomNavBarMobileSkeleton from './variants/bottom-nav-bar-mobile-skeleton/bottom-nav-bar-mobile-skeleton';
import FeaturedCarouselSkeleton from './variants/featured-carousel-skeleton/featured-carousel-skeleton';
import FooterSkeleton from './variants/footer-skeleton/footer-skeleton';
import FullPageLoader from './variants/full-page-loader/full-page-loader';
import FullWidthCardSectionSkeleton from './variants/full-width-card-section-skeleton/full-width-card-section-skeleton';
import HeaderSkeleton from './variants/header-skeleton/header-skeleton';
import HighlightSectionSkeleton, {
    HighlightSectionSkeletonSize,
} from './variants/highlight-section-skeleton/highlight-section-skeleton';

const Skeleton = ({ variant, ...props }: SkeletonProps) => {
    switch (variant) {
        case SkeletonType.Header:
            return <HeaderSkeleton {...props} />;

        case SkeletonType.FullPageSpinner:
            return <FullPageLoader {...props} />;

        case SkeletonType.BottomNavigationBarMobile:
            return <BottomNavBarMobileSkeleton {...props} />;

        case SkeletonType.HighlightSection:
            return <HighlightSectionSkeleton {...props} />;

        case SkeletonType.PhotoGallery:
            return <HighlightSectionSkeleton {...props} size={HighlightSectionSkeletonSize.COMPRESS} />;

        case SkeletonType.FullWidthCardSection:
            return <FullWidthCardSectionSkeleton {...props} />;

        case SkeletonType.FeaturedCarousel:
            return <FeaturedCarouselSkeleton {...props} />;

        case SkeletonType.Footer:
            return <FooterSkeleton {...props} />;

        default:
            return <Loading />;
    }
};

export default Skeleton;
